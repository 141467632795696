export const currencies = [
    {name: "US Dollar", value: "USD"},
    {name: 'Euro', value: 'EUR'},
    {name: 'Pound Sterling', value: 'GBP'},
    {name: 'Chinese Yuan', value: 'AUD'},
    {name: 'Emirati dirham', value: 'AED'},
    {name: 'Argentine peso', value: 'ARS'},
    {name: 'Australian dollar', value: 'AUD'},
    {name: 'Brazilian real', value: 'BRL'},
    {name: 'Canadian dollar', value: 'CAD'},
    {name: 'Swiss franc', value: 'CLP'},
    {name: 'Chilean peso', value: 'CNY'},
    {name: 'czech koruna', value: 'CZK'},
    {name: 'Hong Kong dollar', value: 'HKD'},
    {name: 'Hungarian florint', value: 'HRK'},
    {name: 'Israeli new shekel', value: 'HUF'},
    {name: 'Indian rupee', value: 'INR'},
    {name: 'Japanese yen', value: 'ILS'},
    {name: 'Norwegian krone', value: 'notwegian krone'},
    {name: 'New Zealand dollar', value: 'new zealand dollar'},
    {name: 'Polish zloty', value: 'polish zloty'},
    {name: 'Romanian', value: 'leu'},
    {name: 'Russian ruble', value: 'russian ruble'},
    {name: 'Saudi Riyal', value: 'saudi riyal'},
    {name: 'Swedish Krona', value: 'swedisn krona'},
    {name: 'Turkish lira', value: 'turkish lira'},
    {name: 'South African rand', value: 'south african rand'},
]

export const mainHotelType = ['hotels','guest houses','house','hostels','resorts'];
export const whereNextSlider = [
    {
        title: 'Asia',
        desc: null,
        img : '@/static/images/where-next-1.jpg',
    },
    {
        title: 'Europe',
        desc: null,
        img : '@/static/images/where-next-2.jpg',
    },
    {
        title: 'North america',
        desc: null,
        img : '@/static/images/where-next-3.jpg',
    },
    {
        title: 'South america',
        desc: null,
        img : '@/static/images/where-next-4.jpg',
    },
    {
        title: 'China',
        desc: null,
        img : '@/static/images/where-next-5.jpg',
    },
]
export const countrySlider = [
    {
      title: 'Marseille',
      desc: '1000+  Accomodations',
      img : '@/static/images/how-well-1.jpg',
    },
    {
      title: 'Nantes',
      desc: '400+ Accomodations',
      img : '@/static/images/how-well-2.jpg',
    },
    {
      title: 'Cannes',
      desc: '1000+ Accomodations',
      img : '@/static/images/how-well-1.jpg',
    },
    {
      title: 'Lyon',
      desc: '400+ Accomodations',
      img : '@/static/images/how-well-2.jpg',
    },
    {
      title: 'Marseille',
      desc: '1000+ Accomodations',
      img : '@/static/images/how-well-1.jpg',
    },
    {
      title: 'Nantes',
      desc: '400+ Accomodations',
      img : '@/static/images/how-well-2.jpg',
    }
]
export const partSlider = [
    {
      title: 'Marseille',
      desc: '1000+ Pet-friendly Accomodations',
      img : '@/static/images/how-well-1.jpg',
    },
    {
      title: 'Nantes',
      desc: '400+ Pet-friendly  Accomodations',
      img : '@/static/images/how-well-2.jpg',
    },
    {
      title: 'Cannes',
      desc: '1000+ Pet-friendly  Accomodations',
      img : '@/static/images/how-well-1.jpg',
    },
    {
      title: 'Lyon',
      desc: '400+ Pet-friendly Accomodations',
      img : '@/static/images/how-well-2.jpg',
    },
    {
      title: 'Marseille',
      desc: '1000+ Pet-friendly Accomodations',
      img : '@/static/images/how-well-1.jpg',
    },
    {
      title: 'Nantes',
      desc: '400+ Pet-friendly Accomodations',
      img : '@/static/images/how-well-2.jpg',
    }
];

export const schemaBreadcrumb = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    "name": "CuddlyNest",
    "legalName": "CuddlyNest INC",
    "additionalType": "https://www.wikidata.org/wiki/Q1276157",
    "url": "https://www.cuddlynest.com/",
    "logo": "https://www.cuddlynest.com/images/logo/cn_logo_hpv2_whit_en.png",
    "foundingDate": "2017",
    "founders": [
      {
        "@type": "Person",
        "givenName": "Ritesh",
        "familyName": "Raj",
        "jobTitle": {
          "@type": "DefinedTerm",
          "name": "Co-Partner & COO",
          "sameAs": [
            "https://www.crunchbase.com/person/ritesh-raj",
            "https://twitter.com/RiteshRajji",
            "https://de.linkedin.com/in/riteshji",
            "https://www.zoominfo.com/p/Ritesh-Raj/2413372551",
            "https://shorttermrentalz.com/tag/ritesh-raj/"
          ]
        }
      },
      {
        "@type": "Person",
        "givenName": "Haitham",
        "familyName": "Saead",
        "jobTitle": {
          "@type": "DefinedTerm",
          "name": "Founder",
          "sameAs": [
            "https://www.linkedin.com/in/haithamsaead",
            "https://twitter.com/haithamsaead",
            "https://angel.co/u/haitham-saead",
            "https://www.facebook.com/haithamsaead"
          ]
        }
      }
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "1402 Waterford Oak Dr. Suite 308 ",
      "addressLocality": "Orlando",
      "addressRegion": "FL",
      "postalCode": "32828",
      "addressCountry": "USA"
    },
    "sameAs": ["https://medium.com/@cuddlynest", "https://www.crunchbase.com/organization/cuddlynest-inc", "https://www.facebook.com/CuddlyNest", "https://twitter.com/CuddlyNest", "https://www.instagram.com/cuddlynest/", "https://www.pinterest.com/CuddlyNest", "https://www.youtube.com/channel/UCco2rGStn1-mtT9Hukd26Zg"]
  };

export const schemaContext = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "CuddlyNest",
    "alternateName": "Hotel search",
    "url": "https://www.cuddlynest.com/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.cuddlynest.com/sr?type={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

export const defaultMetaData = {
    title: "Find & Book Hotels, Vacation Rentals, and more with CuddlyNest",
    description: "Browse 1.2 million properties in over 190 countries to book the best deals on Hotels, Vacation Apartments, Condos, Beach Houses, and Home Rentals.",
    canonical: "https://www.cuddlynest.com/",
    ogImage: "https://www.cuddlynest.com/images/banner/home_og_img.png",
    ogTitle: "CuddlyNest - Vacation Homes, Hotels & Everything in Between",
    ogUrl: "https://www.cuddlynest.com",
    ogSiteName: "CuddlyNest.com",
    ogDescription: "For all types of accommodations, for all travelers, at an unbeatable price, book your next getaway with CuddlyNest. Your next trip will be your best yet.",
    fbAppId: "1422684078055515",
    twitterCard: "summary",
    twitterTitle: "CuddlyNest - Vacation Homes, Hotels & Everything in Between",
    twitterDescription: "For all types of accommodations, for all travelers, at an unbeatable price, book your next getaway with CuddlyNest. Your next trip will be your best yet.",
    twitterUrl: "https://www.cuddlynest.com",
    twitterImage: "https://www.cuddlynest.com/images/banner/home_og_img.png"
};

export const EAType = {
    CLICK: 'Click',
    SCROLL: 'Scroll'
};

export const ECType = {
    '/': 'Home',
    '/booking-payment/': 'Booking Confirmation',
    '/deals/valentines-day': 'Valentines Day',
    '/hotel/': 'LDP Hotel',
    '/listings/': 'LDP VR',
    '/hotels/': 'Geopage (Old) Hotel',
    '/vacation-rentals/': 'Geopage (Old) VR',
    '/reservation/': 'Booking Confirmation',
    '/about-us': 'About Us',
    '/contact-us': 'Contact Us',
    '/coronavirus-covid-19': 'Covid-19',
    '/help': 'Help',
    '/maintenance': 'Maintenance',
    '/newsroom': 'Newsroom',
    // '/our-team': 'Our Team', => redirect stub in next.config.js
    '/privacy-policy': 'Privacy Policy',
    '/sr': 'Search Results',
    '/terms-and-conditions': 'Terms And Conditions',
    '/wishlist': 'Wishlist',
    '/l/': 'Geopage',
    '/list_space': 'LNP',
    '/cancellation_policies': 'Cancellation Policies'
};

export const seoPageType = {
    l: {
        id: 'A',
        page: 'Location (aka Geo)',
        slug: 'l'
    },
    t: {
        id: 'B',
        page: 'Theme',
        slug: 't'
    },
    a: {
        id: 'C',
        page: 'Accommodation Type',
        slug: 'a'
    },
    lt: {
        id: 'D',
        page: 'Location Theme',
        slug: 'lt'
    },
    la: {
        id: 'E',
        page: 'Location Acc-type',
        slug: 'la'
    },
    ta: {
        id: 'F',
        page: 'Theme Acc-type',
        slug: 'ta'
    },
    lta: {
        id: 'G',
        page: 'Location Theme Acc-type',
        slug: 'lta'
    }
};

export const LDPTypes = {
  HOTEL: 'hotel',
  NON_HOTEL: 'listings'
};

export const PROVIDERS = {
  EXPEDIA: 'Expedia',
  EXPEDIA_ID: '35',
  EXPEDIA_ID_NUMBER: 35,
};

export const TAX_TYPE = {
  LOCAL_TAX: 'Local tax',
};

export const DestinationTypeEnum = {
    COUNTRY: 'Country',
    STATE: 'State',
    CITY: 'City',
    HOTEL: 'Hotel',
    AIRPORT: 'Airport',
    VR: 'VR',
    POI: 'POI',
    NEIGHBOURHOOD: 'Neighbourhood',
    AROUND: 'Around',
    VIEWPORT: 'Viewport'
};

export const GeoPageTypes = {
    CONTINENT: 'cn',
    COUNTRY: 'co',
    STATE: 'st',
    CITY: 'ct'
};

export const TripTypeEnum = {
    business: 'business',
    family: 'family',
    romantic: 'romantic',
    'digital nomad': 'digital nomad',
    adventure: 'adventure',
    group: 'group',
    solo: 'solo'
};

export const ActivityTypeEnum = {
    'shared pool': 'Shared Pool',
    'gym': 'Gym',
    'ski access': 'Ski Access',
    'karaoke': 'Karaoke',
    'mahjong/chess/poker room': 'Mahjong/chess/poker room',
    'billiards': 'Billiards',
    'table tennis': 'Table tennis',
    'indoor swimming pool': 'Indoor swimming pool',
    'bowling': 'Bowling',
    'tennis': 'Tennis',
    'squash': 'Squash',
    'golf course': 'Golf course',
    'virtual golf': 'Virtual golf',
    'sunbathing area': 'Sunbathing area',
    'outdoor swimming pool': 'Outdoor swimming pool',
    'nightclub': 'Nightclub',
    'diving': 'Diving',
    'football': 'Football',
    'basketball': 'Basketball',
    'beach volleyball': 'Beach volleyball',
    'pool/snooker': 'Pool/snooker',
    'casino': 'Casino',
    'fishing': 'Fishing',
    'game room': 'Game room',
    'hiking': 'Hiking',
    'skiing': 'Skiing',
    'snorkeling': 'Snorkeling',
    'water sports': 'Water sports',
    'swimming pool': 'Swimming pool',
    'on-site restaurant': 'On-site restaurant',
    'on-site bar': 'On-site bar'
};

export const ThemeType = {
    LIGHT: 'LIGHT',
    DARK: 'DARK'
};

export const MediaSrcSetEnum = {
    MOBILE: '(max-width: 600px)',
    TABLET: '(min-width: 601px) and (max-width:1135px)',
    DESKTOP: '(min-width: 1136px)'
};

export const MediaSrcSetEnumOnly = {
    MOBILE: 600,
    TABLET_MIN: 601,
    TABLET_MAX: 1135,
    DESKTOP: 1136
};

export const defaultSourceImageProps = {
    type: 'image/webp'
};

export const AmenityIconSets = {
    BLACK: 'black',
    DARK_GREY: 'dark-grey',
    SLATE: 'slate',
    SUBTLE_GREY: 'subtle-grey',
    WHITE: 'white',
    LIGHT_GREY:'light-grey'
};

export const Environments = {
  LOCAL: 'local',
  DEVELOP: 'develop',
  STAGING: 'staging',
  PRODUCTION: 'production'
};

export const SrpListingsDataSource = {
  CACHE: '[CACHE]',
  DEFAULT: '[DEFAULT]'
};

export const ApiRequestStatus = {
  Idle: 'Idle',
  Pending: 'Pending',
  Success: 'Success',
  Error: 'Error'
};

export const PromoInclusions = {
  FREE_CANCELLATION: 'Free cancellation',
  BREAKFAST_INCLUDED: 'Breakfast included'
};

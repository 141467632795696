import { useState } from "react";
import { createContext, memo } from "react";

export const SharedResourceContext = createContext(null);
export const SharedResourceDispatchContext = createContext(null);

const SharedResource = ({ children, value = null }) => {
    const [sharedResource, setSharedResource] = useState(value);

    return (
        <SharedResourceContext.Provider
            value={sharedResource}
        >
            <SharedResourceDispatchContext.Provider
                value={setSharedResource}
            >
                {children}
            </SharedResourceDispatchContext.Provider>
        </SharedResourceContext.Provider>
    );
};

export default memo(SharedResource);

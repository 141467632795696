import { config } from '@/src/utils/config'; //"@/src/utils/config"

const BaseURL = `${config.api_url}/${config.api_ver}`;
const DefaultHeaders = { 'Content-Type': 'application/json', 'Accept': 'application/json', };

const API_MESSAGE_DEFAULT = {
    SUCCESS: 'successfull',
    INTERAL_ERROR: 'An unexpected error has occurred. Please try again later.'
}

const urlParams = filter =>
            Object.keys(filter)
            .filter(key => key !== undefined && (filter[key] === 0 || filter[key]))
            .map(key => {
            if (Array.isArray(filter[key])) {
                return filter[key].map(value => `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`).join('&');
            } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`;
            }
            })
            .join('&');


class Api {

    getUserId () {
        const userDetails = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};
        return userDetails?._id
    }

    async _base(url, option) {
        const request = await fetch(url, option);
        const { status } = request;
        let response = null;
        let message = '';

        if (status === 500) {
            message = API_MESSAGE_DEFAULT.INTERAL_ERROR;
        } else {
            response = await request.json();
            message = API_MESSAGE_DEFAULT.SUCCESS;
        }

        // TODO, search to see why {...request, response} not working
        return Object.assign(request, {
            response,
            message
        })
    }

    async _get(url, config = {}) {
        const headers = {
            ...DefaultHeaders,
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
            ...config.headers
        };

        return this._base(url, { headers: headers });
    }

    async _post(url, body, config = {}) {
        const headers = {
            ...DefaultHeaders,
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
            ...config.headers
        };

        return this._base(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        });
    }

    async _put(url, body, config = {}) {
        // const headers = {
        //     ...DefaultHeaders
        // };
        // headers['x-api-key'] = process.env.NEXT_PUBLIC_API_KEY;
        const headers = {
            ...DefaultHeaders,
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
            ...config.headers
        };

        /**
         * bitch ass Shayan, who codes like that? this ain't a Python
         *
        if (this.token)
            headers['Authorization'] = this.token;

        */

        return this._base(url, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        });
    }

    async _delete(url, config = {}) {
        const headers = {
            ...DefaultHeaders,
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
            ...config.headers
        };

        return this._base(url, {
            method: 'DELETE',
            headers: headers
        });
    }

    /**
     * Products
     */
    fetchRoomAmenities(roomMoreDataApi) {
        return this._get(`${[config.ldp_ws_service.replace('wss:', 'https:'), roomMoreDataApi].join('')}`);
    }

    async fetchProductMeta(product_id) {
        return this._get(`${BaseURL}/product/metaInfo?product_id=${product_id}`);
    }

    async fetchProductDetail(product_id) {
        const user_id = this.getUserId();
        const obj = {
            product_id,
            user_id
        }
        return this._get(`${BaseURL}/productDetail?${urlParams(obj)}`);
    }
    async fetchProductImages(product_id) {
        const user_id = this.getUserId();
        return this._get(`${BaseURL}/product/images?${urlParams({
            product_id,
            limit: 100,
            offset: 0,
            user_id
        })}`);
    }
    async fetchProductReview(product_id, limit = 10, offset = 0) {
        const user_id = this.getUserId();

        return this._get(`${BaseURL}/product/reviews?${urlParams({
            product_id,
            offset,
            limit,
            user_id
        })}`);
    }
    async fetchSimilarProperties(data) {
        return this._post(
            `${config.tSocketApiEndpoint}/similar_hotel`,
            data,
            {
                headers: {
                    'x-api-key': undefined
                }
            }
        );
    }
    async fetchNearbyPlaces(product_id) {
        return this._get(`${BaseURL}/product/places_nearby?product_id=${product_id}`);
    }
    async fetchProductAvailability(params) {
        return this._get(`${BaseURL}/product/availability_data${params ?? ''}`);
    }
    async fetchProductCharge(filter) {
        return this._get(`${BaseURL}/product/quote?${urlParams(filter)}`);
    }
    /**
     * @deprecated Use checkProductAvailability instead
     * For more information https://www.pornhub.com/model/thestartofus
     */
    async fetchProductUnit(filter) {
        const user_id = this.getUserId();
        return this._get(`${BaseURL}/listing_detail_page/hotel_rooms_and_prices?${urlParams({...filter, user_id})}`);
    }
    async checkProductAvailability(filter) {
        const user_id = this.getUserId();
        return this._get(`${BaseURL}/listing_detail_page/hotel_rooms_and_prices/check_parallel?${urlParams({...filter, user_id})}`);
    }
    async fetchAutosuggestionDates(filter) {
        const user_id = this.getUserId();
        return this._get(`${BaseURL}/listing_detail_page/hotel_rooms_and_prices/get_autosuggestion_dates?${urlParams({...filter, user_id})}`);
    }
    async createBook(filter, token) {
        const url = `${BaseURL}/product/request_to_book?${urlParams(filter)}`;
        return token ? this._get(url, { headers: { 'X-Authorization': `Bearer ${token}` } }) : this._get(url) ;
    }
    async createContactHost(user_token, product_id, checkin, checkout, guest_no, content) {
        return this._get(
            `${BaseURL}/product/contact_host?product_id=${product_id}&checkin=${checkin}&checkout=${checkout}&no_of_guests=${guest_no}&msg_text=${content}`, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    /**
     * New Product
     */
    async fetchLNPBootstrap(token, product_id) {


        let url = `${BaseURL}/lnp/getBootstrapData`;

        if(product_id)
            url += `?product_id=${product_id}`;

        return this._get(
            url , {
                headers: {
                    'X-Authorization': `Bearer ${token}`
                }
            }
        );
    }
    async saveLNPPropertyTypes(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/savePropertyTypes`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPSleeping(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/bedTypes`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPLocation(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/location`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPFacilities(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/facilities`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPRules(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/rules`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPAmenities(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/amenities`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPPhoto(user_token, data) {
        const headers = {
            ...DefaultHeaders,
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
            'X-Authorization': `Bearer ${user_token}`,
        };

        delete headers.Accept;
        delete headers['Content-Type'];

        return this._base(`${BaseURL}/lnp/save_images`, {
            method: 'POST',
            headers: headers,
            body: data,
        });
    }

    async saveLNPUnitPhoto(user_token, data) {
        const headers = {
            ...DefaultHeaders,
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
            'X-Authorization': `Bearer ${user_token}`,
        };

        delete headers.Accept;
        delete headers['Content-Type'];

        return this._base(`${BaseURL}/lnp/save_unit_images`, {
            method: 'POST',
            headers: headers,
            body: data,
        });
    }

    async saveLNPUpdatePhoto(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/updateImagesInfo`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPDeletePhoto(user_token, product_id, photo_id) {
        return this._delete(
            `${BaseURL}/lnp/prd_id/${product_id}/img_id/${photo_id}`, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async getLNProductPhotos(user_token, product_id) {
        return this._get(
            `${BaseURL}/lnp/product_images?product_id=${product_id}`, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPPhotosOrdering(user_token, data) {
        const headers = {
            ...DefaultHeaders,
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
            'X-Authorization': `Bearer ${user_token}`,
        };

        // delete headers.Accept;
        // delete headers['Content-Type'];

        return this._base(`${BaseURL}/lnp/set_image_priority`, {
            method: 'POST',
            headers: headers,
            body: data,
        });
    }

    async saveLNPDescription(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/description`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPPrice(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/pricing`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async createCustomCharge(user_token, product_id, data) {
        return this._post(
            `${BaseURL}/lnp/${product_id}/pricing/custom_charges`,
            data,
            { headers: { 'X-Authorization': `Bearer ${user_token}` } }
        );
    }

    async changeCustomCharge(user_token, product_id, data) {
        return this._put(
            `${BaseURL}/lnp/${product_id}/pricing/custom_charges`,
            data,
            { headers: { 'X-Authorization': `Bearer ${user_token}` } }
        );
    }

    async deleteCustomCharge(user_token, product_id, charge_id) {
        return this._delete(
            `${BaseURL}/lnp/${product_id}/pricing/custom_charges/${charge_id}`,
            { headers: { 'X-Authorization': `Bearer ${user_token}` } }
        );
    }

    async saveLNPAvailability(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/availability_calendar`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPPublishVerify(user_token, product_id) {
        return this._get(
            `${BaseURL}/lnp/publish_unpublish_product?product_id=${product_id}`, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async saveLNPPublish(user_token, data) {
        return this._post(
            `${BaseURL}/lnp/publish_unpublish_product`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }
    async sendRport(listingId, data, user_token) {
        return this._post(
            `${BaseURL}/listings/${listingId}/report`, data, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    async LNPpreview(user_token, product_id) {
        return this._get(
            `${BaseURL}/productDetail?product_id=${product_id}&preview=1`, {
                headers: {
                    'X-Authorization': `Bearer ${user_token}`
                }
            }
        );
    }

    /**
     * Trip Type
     */

    async getThemePageBootstrap(page_slug) {
        return this._get(`${BaseURL}/bootstrap-api/${page_slug}`);
    }
    async getThemePageBreadcrumbs(page_slug) {
        return this._get(`${BaseURL}/breadcrumbs/${page_slug}`);
    }
    async getThemePageFaq(page_slug) {
        return this._get(`${BaseURL}/faqs/${page_slug}`);
    }
    async getThemePageAmenities(page_slug) {
        return this._get(`${BaseURL}/amenities/${page_slug}`);
    }
    async getThemePageVacationRental(page_slug) {
        return this._get(`${BaseURL}/types/${page_slug}/vacation-rental-types?limit=8`);
    }
    async getThemePageSkiResorts(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/ski-resorts`);
    }
    async getThemePageInspiringIndia(sectionType) {
        return this._get(`${BaseURL}/destinations/t-spiritual-journeys/${sectionType}`);
    }

    async getThemePageAmericanRoadTrips(page_slug) {
        return this._get(`${BaseURL}/american_road_trips/${page_slug}`);
    }
    async getThemePageLakesideGetaway(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/lakeside-getaway`);
    }
    async getThemePageSaharaLands(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/sahara-lands`);
    }
    async getThemePageCitiesNightlifeScene(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/cities-nightlife-scene`);
    }
    async getThemePageCitiesAdventureTravel(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/cities-adventure-travel`);
    }
    async getThemePageCitiesSustainableTourism(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/cities-sustainable-tourism`);
    }
    async getThemePageCitiesSlowFoodTourism(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/cities-slow-food-tourism`);
    }
    async getThemePageTopDestinations(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/top-destinations`);
    }
    async getThemePageCaribbean(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/caribbean-islands`);
    }
    async getThemePageLuxuryVillas(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/luxury-villas`);
    }
    async getThemePageFamilyVillas(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/family-villas`);
    }
    async getThemePageDestinationsType(page_slug, type) {
        return this._get(`${BaseURL}/destinations/${page_slug}/${type}`);
    }
    async getThemePageTypes(page_slug, type) {
        return this._get(`${BaseURL}/types/${page_slug}/${type}`);
    }


    async getThemePageTripStayType(page_slug) {
        return this._get(`${BaseURL}/types/${page_slug}/stay-types?limit=7`);
    }
    async getThemePageDreamDestinations(page_slug) {
        return this._get(`${BaseURL}/destinations/${page_slug}/dream-destinations`);
    }
    async getThemePageTopLocation(sectionType){
        return this._get(`${BaseURL}/theme-pages/${sectionType}`);
    }
    async getThemePageTopLocationStay(sectionType){
        return this._get(`${BaseURL}/theme-pages/top-locations/${sectionType}`);
    }

}

const API = new Api();

export default API;
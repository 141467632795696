import React, { memo, useEffect, useRef } from 'react';
import { setAuthData, setupLogin, validateTokenCreds } from "@/redux/actions/authActions";
import { FB_SIGNUP_SIGNIN_SUCCESS, GOOGLE_SIGNUP_SIGNIN_SUCCESS, APPLE_SIGNUP_SIGNIN_SUCCESS, LOGIN_SUCCESS, SIGNUP_SUCCESS } from "@/redux/types";
import { useDispatch, useSelector } from "react-redux";
import SharedResource, { useSharedResourceDispatch } from '@/providers/SharedResource';

const AuthWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const resolveAuth = useSharedResourceDispatch();
    const authActionType = useSelector(state => state.authReducer.type);
    const timerId = useRef(null);

    const setAuthPolling = () => {
        const fn = () => {
            if (!validateTokenCreds()) {
                // dispatch(setAuthData({ data: null, message: null }));
                dispatch(setAuthData({ data: false, message: null }));
                clearInterval(timerId.current);
            }
        };
        fn();
        timerId.current = setInterval(fn, 1000);
    };

    const helpers = {
        setAuthPolling,
        resolveAuth,
        dispatch
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        const {
            setAuthPolling,
            resolveAuth,
            dispatch
        } = helpersRef.current;

        setupLogin(null, dispatch)
            .finally(() => resolveAuth(Promise.resolve(true)))
            .then(setAuthPolling);

        return () => {
            clearInterval(timerId.current);
            timerId.current = null;
        };
    }, []);

    useEffect(() => {
        if ([
            LOGIN_SUCCESS,
            SIGNUP_SUCCESS,
            GOOGLE_SIGNUP_SIGNIN_SUCCESS,
            FB_SIGNUP_SIGNIN_SUCCESS,
            APPLE_SIGNUP_SIGNIN_SUCCESS
        ].includes(authActionType)) {
            if (timerId.current !== null) {
                clearInterval(timerId.current);
                timerId.current = null;
            }

            helpersRef.current.setAuthPolling();
        }
    }, [authActionType]);

    return (
        <>
            {children}
        </>
    );
};

export default memo(props => (
    <SharedResource>
        <AuthWrapper {...props} />
    </SharedResource>
));
